import { Link } from '@remix-run/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import notFound from '~/assets/error-404.svg';

export default function NotFound() {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="flex max-w-[425px] flex-col items-center">
        <img src={notFound} alt="Insomnia Not found logo" />
        <p className="mb-[10px] mt-[30px] text-[22px] font-normal leading-[28px] tracking-[-0.25px] text-black">
          Page Not Found
        </p>
        <p className="text-[14px] font-normal leading-[18px] tracking-[-0.25px] text-[#000000d9]">
          We could not find the page you are looking for.
        </p>
        <Link
          to="/"
          style={{
            display: 'flex',
            alignItems: 'center',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '18px',
            letterSpacing: '-0.25px',
            textTransform: 'none',
            color: '#4000BF',
            padding: '10px 0px',
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} className="h-[16px] w-[16px] text-primary" />
          Back to dashboard
        </Link>
      </div>
    </div>
  );
}
